.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup {
  background-color: white;
  padding: 30px;
  width: 400px;
  max-width: 90vw;
  border-radius: 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.audioWave {
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordBtn {
  padding: 12px 20px;
  font-size: 18px;
  background-image: linear-gradient(
    90deg,
    #0047ab24 0%,
    #8a5cf627 50%,
    #de34f82e 100%
  );
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.recordBtn:hover {
  background-image: linear-gradient(
    90deg,
    #0047ab24 0%,
    #8a5cf627 50%,
    #de34f82e 100%
  );
  border-radius: 10px !important;
}

.recordBtn:active {
  transform: scale(0.98);
}

.text {
  margin-top: 15px;
  font-size: 16px;
  color: #333;
  text-align: center;
  word-break: break-word;
}

.recordingIndicator {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-weight: bold;
}

.recordingDot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
